:root {
	--color-black: #1f1c26;
	--color-black-shadow: #120e17;
	--color-yellow: #FFED4A;
	--header-height: 50px;
}

a {
	text-decoration: none;
}

header {
	align-items: center;
	background: var(--color-black);
	display: flex;
	height: var(--header-height);
	left: 0;
	justify-content: space-between;
	padding: 0 30px;
	position: fixed;
	right: 0;
}

header h6 {
	color: white;
	font-family: "Alegreya Sans";
	font-size: 32px;
	line-height: 33px;
	/* border-bottom: 3px solid var(--color-yellow); */
	border-radius: 3px;
}

header nav {
	display: flex;
}

header nav a {
	color: white;
	font-family: "Open Sans";
	font-size: 16px;
	margin-right: 50px;
}

header nav a:hover {
	color: var(--color-yellow);
}

main {
	padding-top: var(--header-height);
}

main section.motorcycle-image {
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 600px;
	overflow: hidden;
}

main p.name {
	background: var(--color-yellow);
	color: var(--color-black);
	text-align: center;
	padding: 10px 0;
	font-family: "Alegreya Sans";
	font-size: 26px;
  margin-top: 0px;
}

main img {
	display: block;
	width: 40%;
	height: auto;
	margin: 30px auto;
	min-width: 375px;
}

main section.information {
	line-height: 40px;
	width: 70%;
	margin: auto;
	margin-bottom: 50px;
	font-family: "Open Sans";
	font-size: 24px;
	display: flex;
	justify-content: space-around;
}

main section.specs {
	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 400px;
	margin-left: 50px;
}

main section.specs strong {
	color: var(--color-black);
}

main iframe {
	display: block;
	width: 70%;
	height: 40vw;
	margin: auto;
	margin-bottom: 50px;
}

@media screen and (max-width: 1075px) {
	main section.information {
		flex-direction: column;
	}
	main section.specs {
		margin-left: 0px;
		margin-top: 20px;
		flex-basis: 0px;
	}
}
